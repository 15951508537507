@import "../../_global.less";

body {
  background-color: @them__color_body;
  margin: unset;
}

.w-100 {
  width: 100%;
}

.justify-content-center {
  .mixin-flex-justify-content(center);
}

.justify-content-space-between {
  .mixin-flex-justify-content(space-between);
}

.justify-content-end {
  .mixin-flex-justify-content(end);
}

.card {
  padding: 20px;

  .p-card {
    box-shadow: none;

    .p-card-header{
      background-color: @theme__color__primary;
      border-top-left-radius: @indent__sx;
      border-top-right-radius:@indent__sx;
      color: @color-white;
      padding-left: @indent__l;
    }

    .title-box {
      justify-content: space-between;
      align-items: center;
      height: min-content;
      border-bottom: 1px solid @theme__color__secondary;

      .page-title-wrapper {
        .mixin-display(flex);
        .mixin-display-flex-direction(column);
        margin-left: @indent__base;

      }


      .courseCreateButton {
        align-items: center;
        justify-content: center;
      }
    }

    .flex {
      .mixin-flex-justify-content(space-between);
    }

  }

  .p-card-body {
    border-radius: @indent__m;
    min-height: 400px;

    .p-card-content {
      padding: unset;

    }

  }


  .page-title {
    color: @theme__color__primary;
    margin: unset;
    margin-bottom: @indent__sx;
  }
}


// Inputs

.formGroup {
  .p-autocomplete {
    width: 100%;

    input {
      width: 100%;
    }
  }

  label {
    color: @theme__color__secondary;
    font-weight: @font-weight__bold;
    margin-top: @indent__xxs;
  }

  .inputGroup {
    .mixin-display();
    .mixin-gap(@indent__xxs);
    .mixin-flex-grow(1);
    .mixin-flex-justify-content(space-evenly);

    .flex {
      width: 100%;
    }
  }

  .has-error {
    input {
      border-color: @theme__color__danger;
    }

    .p-dropdown {
      border-color: @theme__color__danger;
    }

    .error-message {
      .mixin-display(flex);
      .mixin-gap(@indent__xxs);
      color: @theme__color__danger;

    }
  }
}
.error-message {
  color: @theme__color__danger;
  font-size: @font-size__s;
  margin-top: @indent__xxs;
}

// Formulário Perfil do usuário
.w-full {
  .p-calendar {
    width: 100%;
  }
}

.button_input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

// Dropdown
.p-dropdown {
  width: 100%;
}

// Table
.p-datatable .p-datatable-header {
  background: @color-white;
  border: unset;
}


.short-link {
  .p-inputtext {
    .inputMixin(@primary__color__lighter, @theme__color__primary, 0, @indent__m, @theme__color__primary, @indent__m);
  }
}


/// post-it - Componente do agregador de links
@shadow-color: rgba(0, 0, 0, 0.3);
@hover-shadow: rgba(0, 0, 0, 0.2);
@corner-size: 40px;
@rotate-angle: -3deg;
@hover-scale: 1.05;

.p-error {
  color: @color-error;
  display: flex;
  align-items: center;
  font-size: @font-size__s;
  margin-top: @indent__xxs;

  &::before {
    content: "\e922"; /* Código do ícone pi-exclamation-triangle */
    font-family: 'PrimeIcons';
    margin-right:@indent__xxs;
  }
}


.bar-action {
  .mixin-display(flex);
  margin-top: @indent__xl;
  justify-content: space-between;

  &__search {
    width: 100%;
    flex: 0.5;
  }

  &__bt {
    .mixin-display(flex);
    gap: @indent__xxs;
  }
}
