@import "../theme.less";
//
//.mixin-table(
//    @_table_header-background-color: @theme__color__primary,
//    @_table_header-color: @theme__color__primary-alt,
//    @_table_header-font-size: @font-size__l,
//    @_table_header-border: 0.05px solid @theme__color__primary-alt ) {
//    .p-datatable {
//        margin-top: @indent__m;
//        .p-datatable-header {
//            border: none;
//            background: none;
//        }
//
//        .p-datatable-wrapper {
//            .p-datatable-table {
//                .p-datatable-thead {
//                    tr {
//                        th {
//                            background-color: @_table_header-background-color;
//                            color: @_table_header-color;
//                            font-weight: @font-weight__bold;
//                            font-size: @_table_header-font-size;
//                            border: @_table_header-border;
//                        }
//
//                        .thStyleAction {
//                            width: 5%;
//                        }
//                    }
//                }
//
//                .p-datatable-tbody {
//                    .trBody {
//                        td {
//                            font-weight: @font-weight__semibold; // 600 é equivalente à semi-bold de acordo com a MDN doc
//                            font-size: @indent__sx;
//                            padding: @indent__sx + 1;
//                            height: min-content;
//                            border: solid @theme__color__primary-alt 1px;
//                        }
//
//                    }
//                }
//            }
//        }
//
//        .actionItens {
//            .mixin-display(flex);
//            .mixin-flex-justify-content(center);
//            .mixin-display-flex-box-align(center);
//
//
//            a {
//                .pi {
//                    margin: @indent__sx;
//                    cursor: pointer;
//                }
//
//                .pi-trash {
//                    cursor: pointer;
//                }
//
//                .pi-pencil:hover {
//                    transition: 0.2s;
//                    color: @color-blue-light;
//                }
//
//                .pi-trash:hover {
//                    transition: 0.2s;
//                    color: @color-red;
//                }
//            }
//        }
//
//        .p-datatable-footer {
//            background-color: @color-blue-light;
//
//            height: 41px;
//        }
//    }
//};
@background-color: #f9f9ff;
@text-color: @theme__color__primary;
@text-light: #444;
@border-color: #e0e0e0;
@hover-bg: #f1f5ff;
@danger-color: #ff4d4f;
@border-radius: 12px;
@font-size: 1rem;
@padding-cell: 16px;
@paginator-active-bg: @theme__color__primary;
@paginator-hover-bg: rgba(86, 56, 205, 0.1);

.p-datatable {
  background: @background-color;
  border-radius: @border-radius;
  border: none;
  font-size: @font-size;


  /* Cabeçalho da Tabela */

  .p-datatable-thead {


    th {
      background: #fff;
      font-weight: bold;
      color: @theme__color__primary;
      padding: @padding-cell;
      border-bottom: 1px solid @border-color;
      text-align: left;
      font-size: 14px;
    }
  }

  /* Corpo da Tabela */

  .p-datatable-tbody {
    tr {
      background: #fff;
      transition: all 0.3s ease;

      /* Efeito hover */

      &:hover {
        background: @hover-bg;
      }

      td {
        padding: @padding-cell;
        border-bottom: 1px solid @border-color;
        color: @text-light;
        vertical-align: middle;

        /* Ajustando input de edição */

        input.p-inputtext {
          width: 100%;
          border: 1px solid @border-color;
          padding: 8px;
          border-radius: 6px;
        }
      }
    }
  }

  /* Ícones e Botões dentro da Tabela */

  .p-button {
    border-radius: 8px;
    padding: 8px;
    font-size: @font-size;

    &.p-button-danger {
      background: @danger-color;
      border: none;
    }
  }

  /* Checkbox para seleção */

  .p-checkbox {
    margin-right: 10px;
  }

  /* Ícone de ação (três pontinhos) */

  .actions-icon {
    font-size: 1.5rem;
    cursor: pointer;
    color: #999;
    transition: color 0.3s;

    &:hover {
      color: @theme__color__primary;
    }
  }

  /* Paginação */

  .p-paginator {
    background: transparent;
    border: none;
    padding: 10px 0;

    .p-paginator-pages {
      display: flex;
      gap: 5px;

      .p-paginator-page {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-weight: bold;
        transition: all 0.3s ease;
        cursor: pointer;

        &.p-highlight {
          background: @paginator-active-bg;
          color: white;
        }

        &:hover {
          background: @paginator-hover-bg;
        }
      }
    }
  }
}

