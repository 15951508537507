@import "../theme.less";
.label(){
  color: @theme__color__primary;
  left: @indent__m;
  font-size: @font-size__s;
}

.p-inputtext {
  background-color: @theme__color__primary-alt;
  color:@theme__color__primary;
  margin: unset;
  width: 100%;
  border-radius: @indent__xxs;
  padding: @indent__xxs @indent__sx;
  font-family: @font-family__base;

  &:enabled:focus{
    border-color: @theme__color__primary;
    box-shadow: unset;
  }

  + label {
    .label()
  }
}

.p-password, .p-password-mask {
  width: 100%;
  color:@theme__color__primary;


  + label {
    .label()
  }
}
.inputMixin(@backgroundColor, @textColor, @borderRadius, @paddingLeft, @labelColor, @labelLeft) {
  background-color: @backgroundColor;
  color: @textColor;
  margin: unset;
  width: 100%;
  border-radius: @borderRadius;
  padding-left: @paddingLeft;

  + label {
    .label()
  }
}

.field > label{
  .label()
}
