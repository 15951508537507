@import "../theme.less";

// Mixin para personalizar botões do PrimeNG
.mixin-button-styles(
  @buttons-primary-background-color: @theme__color__primary,
  @buttons-primary-background-color-hover: @theme__color__black,
  @buttons-primary-color: #ffffff,
  @buttons-primary-width: auto,
  @buttons-padding: 0.5rem 1rem,
  @border-primary: @buttons-border-radius,
  @buttons-secondary-background-color: @primary__color__lighter,
  @buttons-secondary-background-color-hover: @primary__color__dark,
  @buttons-secondary-color: @theme__color__black,
  @button-text-background-color: transparent,
  @button-text-color: @theme__color__primary,
  @button-text-background-hover: rgba(59, 130, 246, 0.04),
  @button-text-color-hover: @theme__color__primary
) {
  .p-button.p-element {
    padding: unset;
    border: unset;
    background-color: unset;

    &.w-full {
      button.p-button.p-element {
        width: 100%;
      }
    }
  }

  button.p-button.p-element {
    border-radius: @border-primary;
    padding: @buttons-padding;
    border: none;
    font-size: 1rem;

    .p-button-label {
      font-size: @indent__s;
      font-weight: @font-weight__heavier;
      font-family: @font-family__base;

    }

    &:not(:disabled) {
      background-color: @theme__color__primary;
      color: @buttons-primary-color;

      &:hover {
        background-color: @buttons-primary-background-color-hover;
        color: @buttons-primary-color;
      }

      &.p-button-text {
        background-color: @button-text-background-color;
        color: @button-text-color;

        &:hover {
          background-color: @button-text-background-hover;
          color: @button-text-color-hover;
        }
      }
    }

    &.p-button-primary {
      background-color: @buttons-primary-background-color;
      color: @buttons-primary-color;
      width: @buttons-primary-width;

      &:hover {
        background-color: @buttons-primary-background-color-hover;
        color: @buttons-primary-color;
      }
    }

    &.p-button-secondary {
      color: @buttons-secondary-color;
      background-color: @buttons-secondary-background-color;
      border: none;

      &:hover {
        background-color: @buttons-secondary-background-color-hover;
      }
    }

    &.p-button-success {
      background-color: #28a745;

      &:hover {
        background-color: darken(#28a745, 10%);
      }
    }

    &.p-button-danger {
      background-color: #dc3545;

      &:hover {
        background-color: darken(#dc3545, 10%);
      }
    }

    &.p-button-warning {
      background-color: #ffc107;
      color: #212529;

      &:hover {
        background-color: darken(#ffc107, 10%);
      }
    }

    &.p-button-info {
      background-color: #17a2b8;

      &:hover {
        background-color: darken(#17a2b8, 10%);
      }
    }

    &.p-button-help {
      background-color: #6610f2;

      &:hover {
        background-color: darken(#6610f2, 10%);
      }
    }

    &.p-button-contrast {
      background-color: #343a40;
      color: #ffffff;

      &:hover {
        background-color: darken(#343a40, 10%);
      }
    }

    &.p-button-height-default {
      height: 3rem;
    }
  }
}

// Chamada do mixin para aplicar os estilos
.mixin-button-styles();


.link-button {
  width: 100%;
  position: relative;

  .p-button {
    width: 100%;
    padding: @indent__s;

    .pi {
      //position: absolute;
      transform: rotate(45deg) scale(@hover-scale);
      color: @postit-bg;
    }
  }
}

// Botões agrupados com campo de busca
.p-inputgroup:has(> p-button:first-child) .p-button{
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

