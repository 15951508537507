/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";
@import "@style/_global.less";



// Estilo Global do Corpo
body {
  margin: 0;
  padding: 0;
  font-family: @font-family__base;
  background-color: @them__color_body;
  color: @color-black;

  &.link-tree {
    //height: 100vh;
    //background-image:url("assets/img/bg_paper.jpg");
    //background-size: cover;
    background-color: @theme__color__primary-alt; /* Roxo claro */
    color: @theme__color__primary; /* Roxo escuro */
    text-align: center;
    padding: @indent__base;

  }
}

// Estilo para Links (opcional)
a {
  color: @theme__color__primary;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
