//.sidebar {
//  .opened {
//    opacity: 1;
//    transition: max-height 0.5s ease, opacity 0.5s ease, background-color 0.5s ease;
//    width: 345px;
//    .screen-custom({
//      position: fixed;
//      background: @theme__color__primary;
//      top: 80px;
//      left: 0;
//      right: 0;
//      bottom: 0;
//      max-height: 100%; // Ocupa toda a altura da tela
//      z-index: 100; // Garante que fica por cima de tudo
//      padding: 20px; // Ajuste o padding para mobile se necessário
//    }, 425px);
//
//
//  }
//
//  .closed {
//    max-height: 0;
//    opacity: 0;
//    padding: 0;
//    width: 0;
//  }
//
//  &.sidebar-minimized {
//
//
//    .logo {
//      &__text {
//        opacity: 0;
//        transition: @sidebar-transition;
//      }
//    }
//
//    .p-panelmenu {
//
//      .p-panelmenu-header {
//        &:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content {
//          background-color: @theme__color__primary;
//
//        }
//
//        .p-panelmenu-header-content {
//
//          .p-menuitem-text {
//            opacity: 0;
//            transition: @sidebar-transition;
//          }
//        }
//
//
//      }
//    }
//
//    .p-panelmenu-content {
//      .p-menuitem {
//        .p-menuitem-content {
//          .p-menuitem-link .p-menuitem-text {
//            opacity: 0;
//            transition: @sidebar-transition;
//          }
//        }
//      }
//    }
//
//    &:hover {
//
//      .logo {
//        &__text {
//          opacity: 1;
//
//        }
//      }
//
//      .p-panelmenu {
//        .p-panelmenu-header {
//          .p-panelmenu-header-content {
//
//            .p-menuitem-text {
//              opacity: 1;
//            }
//          }
//
//
//        }
//      }
//
//      .p-panelmenu-content {
//        .p-menuitem {
//          .p-menuitem-content {
//            .p-menuitem-link .p-menuitem-text {
//              opacity: 1;
//            }
//          }
//        }
//      }
//    }
//
//
//  }
//
//  .p-panelmenu {
//    .p-panelmenu-panel {
//      position: relative;
//      margin-bottom: unset;
//    }
//
//    .p-panelmenu-content {
//
//
//      .p-panelmenu-root-list {
//        //padding: 0 0 0 @indent__x + 6;
//      }
//
//      .p-menuitem {
//        &:not(.p-highlight):not(.p-disabled) {
//          &.p-focus > .p-menuitem-content {
//            background-color: @theme__color__secondary;
//            color: @theme__color__primary;
//          }
//
//          & > .p-menuitem-content {
//
//            &:hover {
//              text-decoration: unset;
//              //background-color: @theme__color__primary;
//
//            }
//          }
//
//        }
//
//        & > {
//          .p-menuitem-content {
//            .p-menuitem-link {
//
//              .p-menuitem-text,
//              .p-menuitem-icon,
//              .p-element * {
//                font-size: @font-size__m;
//                font-weight: @font-weight__heavier;
//              }
//
//            }
//
//            &:hover {
//              .p-menuitem-link {
//                &:hover{
//                  text-decoration: unset;
//                }
//
//                .p-menuitem-text,
//                .p-menuitem-icon,
//                .p-element * {
//                  color: @theme__color__primary;
//                }
//
//              }
//
//            }
//          }
//        }
//      }
//
//
//    }
//
//    .p-panelmenu-header {
//      &:hover {
//        color: @theme__color__primary;
//
//        .p-panelmenu-header-content {
//
//          .p-panelmenu-header-action {
//            color: @theme__color__primary;
//          }
//        }
//      }
//
//      .p-panelmenu-header-content {
//        padding: 0 0 0 @indent__xxs;
//        border-radius: 30px 0 0 30px;
//        border: unset;
//        transition: border-radius 0.2s ease-in-out;
//        background-color: @theme__color__primary;
//
//        &:hover {
//          background-color: rgba(255, 255, 255, 0.2);
//          border: unset;
//        }
//
//        .p-panelmenu-header-action {
//          .mixin-display(grid);
//          color: @color-white;
//          grid-template-columns: auto auto 1fr;
//          grid-template-rows: 1fr;
//          grid-column-gap: 0px;
//          grid-row-gap: 0px;
//          font-family: @font-family__base;
//
//
//          &:hover {
//            text-decoration: none;
//            color: @color-white;
//          }
//        }
//
//        .p-element {
//          grid-column: 4 / span 1;
//          font-size: @font-size__m;
//
//        }
//
//        .p-menuitem-icon {
//          grid-area: 1 / 1 / 2 / 2;
//          font-size: 24px;
//        }
//
//        .p-menuitem-text {
//          grid-area: 1 / 2 / 2 / 3;
//          font-size: @font-size__xl;
//          font-weight: @font-weight__regular;
//        }
//      }
//
//      &.p-highlight {
//        .p-panelmenu-header-content {
//          border-radius: 30px 0 0 0;
//          background-color: rgba(255, 255, 255, 0.2);
//
//          .p-panelmenu-header-action {
//            color: @color-white;
//          }
//
//        }
//      }
//    }
//  }
//}


.sidebar {
  .opened {
    opacity: 1;
    transition: max-height 0.5s ease, opacity 0.5s ease, background-color 0.5s ease;
    width: 300px;
    .screen-custom({
      position: fixed;
      background: @theme__color__primary;
      top: 80px;
      left: 0;
      right: 0;
      bottom: 0;
      max-height: 100%; // Ocupa toda a altura da tela
      z-index: 100; // Garante que fica por cima de tudo
      padding: 20px; // Ajuste o padding para mobile se necessário
    }, 425px);


  }

  .closed {
    max-height: 0;
    opacity: 0;
    padding: 0;
    width: 0;
  }

  .p-panelmenu {
    .p-panelmenu-panel {
      background-color: unset;

      .p-panelmenu-header {
        .p-panelmenu-header-content {
          //background-color: rgba(255, 255, 255, 0.2);
          padding: @indent__sx 0 @indent__sx @indent__m;
          border-radius: 30px 0 0 30px;
          border: unset;
          transition: border-radius 0.3s ease-in-out;
          display: flex;
          align-items: center;


          &:hover {
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0.2);
          }

          // titulo do menu principal
          .p-menu-item-link {
            span {
              color: @color-white;
              font-size: 18px;
              padding-right: @indent__s;

            }
          }

          .p-menuitem-text {
            opacity: 0;
            transition: @sidebar-transition;
          }

        }

        &-active {
          .p-panelmenu-header-content {
            background: rgba(255, 255, 255, 0.2);
            color: @color-white;
            border-bottom-left-radius: 0;
          }
        }

        &:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content {
          background-color: rgba(255, 255, 255, 0.2);

          .p-menu-item-link {
            text-decoration: unset;
            color: @color-white;
          }
        }
      }

      // Submenu
      .p-panelmenu-content-container {
        .p-panelmenu-content {
          background: white;

          .p-panelmenu-submenu {
            padding: unset;

            .p-panelmenu-item {
              .p-panelmenu-item-content {
                border-radius: unset;
                padding: 8px;

                a {
                  text-decoration: unset;
                  padding: @indent__sx;
                }
              }

            }
          }
        }
      }


    }
  }


  //.p-panelmenu-content {
  //  .p-menuitem {
  //    .p-menuitem-content {
  //      .p-menuitem-link .p-menuitem-text {
  //        opacity: 0;
  //        transition: @sidebar-transition;
  //      }
  //    }
  //  }
  //}
  //
  //&:hover {
  //  .logo {
  //    &__text {
  //      opacity: 1;
  //    }
  //  }
  //
  //  .p-panelmenu {
  //    .p-panelmenu-header {
  //      .p-panelmenu-header-content {
  //        .p-menuitem-text {
  //          opacity: 1;
  //        }
  //      }
  //    }
  //  }

  //  .p-panelmenu-content {
  //    .p-menuitem {
  //      .p-menuitem-content {
  //        .p-menuitem-link .p-menuitem-text {
  //          opacity: 1;
  //        }
  //      }
  //    }
  //  }
  //}


  .p-panelmenu {
    border: unset;
    background: transparent;

    .p-panelmenu-panel {
      position: relative;
      margin-bottom: unset;
      border: unset;
      border-radius: unset;
      padding: unset;
    }

    .p-panelmenu-header {
      &:hover {
        color: @theme__color__primary;
      }

      //.p-panelmenu-header-content {
      //
      //  .p-panelmenu-header-action {
      //    display: flex;
      //    align-items: center;
      //    color: @color-white;
      //    font-family: @font-family__base;
      //    font-size: @font-size__m;
      //    font-weight: @font-weight__bold;
      //
      //    &:hover {
      //      text-decoration: none;
      //      color: @color-white;
      //    }
      //
      //    .p-menuitem-icon {
      //      font-size: 24px;
      //      margin-right: @indent__xxs;
      //    }
      //
      //    .p-menuitem-text {
      //      font-size: @font-size__xl;
      //      font-weight: @font-weight__regular;
      //    }
      //  }
      //}

      //&.p-highlight {
      //  .p-panelmenu-header-content {
      //    border-radius: 30px 0 0 0;
      //    background-color: rgba(255, 255, 255, 0.2);
      //
      //    .p-panelmenu-header-action {
      //      color: @color-white;
      //    }
      //  }
      //}
    }

    .p-panelmenu-content {
      //padding-left: @indent__xs;

      .p-menuitem {
        &:not(.p-highlight):not(.p-disabled) {
          &.p-focus > .p-menuitem-content {
            background-color: @theme__color__secondary;
            color: @theme__color__primary;
          }

          & > .p-menuitem-content {
            &:hover {
              text-decoration: unset;
            }
          }
        }

        & > .p-menuitem-content {
          .p-menuitem-link {
            display: flex;
            align-items: center;
            padding: @indent__xs;
            border-radius: 20px;
            transition: background-color 0.3s ease-in-out;

            .p-menuitem-text {
              font-size: @font-size__m;
              font-weight: @font-weight__bold;
              margin-left: @indent__xxs;
            }

            .p-menuitem-icon {
              font-size: 20px;
            }

            &:hover {
              background-color: rgba(255, 255, 255, 0.2);
              color: @theme__color__primary;
            }
          }
        }
      }
    }
  }
}

