// /**
//  * Copyright © 2013-2017 Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Typography variables
//  _____________________________________________

//
//  Fonts
//  ---------------------------------------------
@font-face {
  font-family: "Inter", serif;
    src: url('./fonts/Inter/static/Inter_18pt-Regular.ttf') format('ttf'); // Substitua o caminho pelo caminho real do seu arquivo
    font-weight: normal;
    font-style: normal;
}

 @font-face {
    font-family: "Ubuntu", serif; // Defina o nome da sua fonte
   src: url('/assets/styles/fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    //font-family: 'Audiowide';
    //src: url('../fonts/Audiowide/Audiowide-Regular.ttf') format('ttf');
    //src: url('../fonts/Audiowide/Audiowide-Regular.eot');
    //src: url('../fonts/Audiowide/Audiowide-Regular.eot?#iefix') format('embedded-opentype'),
    //    url('../fonts/Audiowide/Audiowide-Regular.woff2') format('woff2'),
    //    url('../fonts/Audiowide/Audiowide-Regular.woff') format('woff');
    //font-weight: normal;
    //font-style: normal;
    //font-display: swap;
}




//  Path


//  Names
@icons__font-name: 'icons-blank-theme'; // ToDo UI: we need to rename (it shouldn't use blank theme name) or move icon fonts to blank theme

//  Font families
@font-family__sans-serif: "Ubuntu", serif;
@font-family__serif: "Audiowide", serif;


//  Sizes
@root__font-size: 62.5%; // Defines ratio between root font size and base font size, 1rem = 10px
@font-size-ratio__base: 1.4; // Defines ratio of the root font-size to the base font-size

@font-size-unit: rem; // The unit to which most typography values will be converted by default


//  Weights
@font-weight__light: 300;
@font-weight__regular: 400;
@font-weight__heavier: 500;
@font-weight__semibold: 600;
@font-weight__bold: 700;

//  Styles
@font-style__base: normal;
@font-style__emphasis: italic;

//  Line heights
@line-height__base: 1.428571429;
@line-height__xl: 1.7;
@line-height__l: 1.5;
@line-height__s: 1.33;



//
//  Borders
//  ---------------------------------------------

@border-width__base: 1px;

.ubuntu(@weight, @style: normal) {
  font-family: "Ubuntu", serif;
  font-weight: @weight;
  font-style: @style;
}

.ubuntu-light {
  .ubuntu(300);
}

.ubuntu-regular {
  .ubuntu(400);
}

.ubuntu-medium {
  .ubuntu(500);
}

.ubuntu-bold {
  .ubuntu(700);
}

.ubuntu-light-italic {
  .ubuntu(300, italic);
}

.ubuntu-regular-italic {
  .ubuntu(400, italic);
}

.ubuntu-medium-italic {
  .ubuntu(500, italic);
}

.ubuntu-bold-italic {
  .ubuntu(700, italic);
}
