// Container
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  // Tamanhos responsívos
  @media (min-width: @breakpoint-sm) {
    max-width: 540px;
  }
  @media (min-width: @breakpoint-md) {
    max-width: 720px;
  }
  @media (min-width: @breakpoint-lg) {
    max-width: 960px;
  }
  @media (min-width: @breakpoint-xl) {
    max-width: 1320px;
  }

  // Row
  .row {
    .mixin-display(flex);
    .mixin-display-flex-wrap(wrap);
    .mixin-flex-justify-content(center);
    margin-right: -15px;
    margin-left: -15px;

    .screen-custom(
    {
      .mixin-display-flex-direction(column);
    }, @breakpoint-md);

    // Column
    [class*="col-"] {
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;

      .screen-custom(
      {
        max-width: 100%;
      }, @breakpoint-md);
    }
  }
  // Colunas
  .col-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }


  // Image
  // Image fluid
  .img-fluid {
    max-width: 100%;
    height: auto;
    display: block;
  }

  .img-responsive {
    max-width: 100%;
    height: auto;
    display: block;
  }
}


