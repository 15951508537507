.topbar {
  .p-toolbar {
    padding: unset;
    background-color: @them__color_body;
    box-shadow: unset;
    border: unset;

    .p-toolbar-group-end {
      .mixin-flex-justify-content(end)
    }
  }
  .container-flex {
    .mixin-display(flex);
    width: 100%;
    margin-bottom: @indent__xl;

    .root.flex {
      width: 100%;

      .title-box {
        .page-title-wrapper {
          .page-title {
            color: @theme__color__primary;
            font-size: @h3__font-size;
            font-family: @font-family__base;
          }

        }
      }
    }
  }
}
